<template>
  <component :is="tag" class="md:tw-grid md:tw-grid-cols-8 md:tw-gap-x-8">
    <slot />
  </component>
</template>

<script>

export default {
  props: {
    tag: {
      default: 'div',
      type: String
    }
  }
}

</script>
