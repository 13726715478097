<template>
  <component :is="tag" class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-w-full tw-mb-7 tw-px-4">
    <slot />
  </component>
</template>

<script>

export default {
  props: {
    tag: {
      default: 'div',
      type: String
    }
  }
}

</script>
