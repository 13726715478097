<template>
  <div>
    <ml-container>
      <ml-grid>
        <div class="tw-mt-12 tw-text-center tw-text-black tw-col-start-2 tw-col-span-6">
          <page-title>Our Founder</page-title>
          <img src="@/assets/img/landing/ana.jpg" alt="Ana">
          <div class="tw-mb-8 tw-leading-relaxed tw-text-left tw-space-y-4 tw-mt-4">
            <p>Hello love birds,</p>
            <p>
              I am Ana, the founder of Mango & Lola! If you are here, you must be getting married, YAY!<br>
            So first of all CONGRATS, I am so happy for you. I always tell my clients that they are blessed to have found each other and have found love. Life is full when there is love, and to me, love is happiness.
            </p>
            <p>
            As an award-winning wedding planner, I certainly know a thing or two about wedding planning. Over the years I have had the pleasure to work with many amazing couples and their families to plan their special day.
            </p>
            <p>
            There is lots of excitement and joy when you first begin the wedding planning process, you are creating a special day that reflects and celebrates your love and commitment to one another, you are planning the party of a lifetime.
            </p>
            <p>
            However, wedding planning can quickly become overwhelming, especially when faced with numerous decisions and details to consider.
            </p>
            <p>
            Couples often feel stressed or anxious about finding the right vendors. On top of that expenses can add up quickly, and couples can find themselves grappling with financial constraints. Balancing your desired vision, finding the right vendors, and managing your wedding budget can be a significant challenge and a source of frustration in your busy life with your demanding schedules.
            </p>
            <p>
            Can you relate?
            </p>
            <p>
            Knowing how hard wedding planning can be, I wanted to make it easier for you to plan your wedding and give you the information you need to confidently book your wedding vendor dream team online.
            </p>
            <p>
            Mango & Lola is your marketplace where you can easily find available vendors in your area. You can see their offerings and price, communicate directly with them, and book them on mangolola.com. All your bookings, and quote requests will be saved in your free private account that you can access from anywhere to manage.
            </p>
            <p>
            I hope you love Mango & Lola as much as our team does. We built this tool for you with lots of thought and love. Good luck with your wedding planning, have fun with Mango & Lola, I know it’s going to be amazing!!!<br>
            xoxo,<br>
            Ana</p>
          </div>
        </div>
      </ml-grid>
    </ml-container>
  </div>
</template>

<script>
/*
import i18n from '@/i18n';
*/
import MlContainer from "@/components/layout/MlContainer";
import PageTitle from "@/components/layout/titles/PageTitle";
import MlGrid from "@/components/layout/MlGrid";

export default {
  components: {
    MlGrid,
    PageTitle,
    MlContainer,
  },
  layout: 'basic',
  metaInfo() {
    return {
      title: 'Our founder',
      meta: [
        {
          name: 'description',
          content: 'As an award-winning wedding planner, I certainly know a thing or two about wedding planning. Over the years I have had the pleasure to work with many amazing couples and their families to plan their special day.'
        }
      ],
    }
  },
}
</script>
